<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    maxWidth
    close
    @clickClose="close(false)"
  >
    <!-- <v-card class="pa-5" flat outlined>
      <v-autocomplete
        v-model="selecthospital"
        :items="hospitals"
        outlined
        label="病院選択"
        item-text="name"
        item-value="code"
        chips
      >
      </v-autocomplete>
      <DatePicker
        v-model="date"
        label="対象年月"
        prepend-inner-icon="mdi-calendar"
        type="month"
        outlined
        next
        previous
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <Btn icon="send" color="success" @click="anytimeclick('query')">取得</Btn>
      </v-card-actions>
    </v-card> -->
    <v-card v-if="!!schedule && schedule.length" class="office mt-5">
      <!-- <v-toolbar color="primary">
        <v-btn class="mx-2" color="success" @click="add()"
          >スポット作業スケジュール追加</v-btn
        >
        <v-btn class="mx-2" color="warning" @click="reference()">
          随時スケジュール追加
        </v-btn>
      </v-toolbar> -->
      <template v-for="affiliation in affiliations">
        <table class="mt-4 mx-4" border="1" :key="affiliation.index">
          <thead>
            <tr>
              <th rowspan="1" class="fixed"></th>
              <th v-for="(cal, i) in schedule" :key="i">
                {{ cal.name }}
              </th>
            </tr>
          </thead>
          <template v-for="n in affiliation.scount">
            <template v-for="packagerow in affiliation.packages[n - 1]">
              <tr :key="packagerow.rowindex">
                <th v-if="packagerow.rowspan > 0" :rowspan="packagerow.rowspan">
                  <Btn
                    color="info"
                    text
                    small
                    @click="
                      anytimefix(affiliation.index, packagerow.index, 'query')
                    "
                    >編集</Btn
                  >
                </th>
                <template v-for="meisai in packagerow.packagemeisais">
                  <td
                    :key="meisai.rowindex"
                    v-if="meisai.rowspan > 0"
                    :rowspan="meisai.rowspan"
                    style="white-space: pre-line; word-wrap: break-word;"
                    :class="meisai.width"
                  >
                    {{ meisai.name }}
                  </td>
                </template>
              </tr>
            </template>
          </template>
        </table>
      </template>
    </v-card>
  </DialogBase>
</template>

<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import moment from "moment";
import * as Dummy from "../dummy";
import ScheduleFixDialog from "./ScheduleFixDialog.vue";

const DATE = new Date();
const DATENOW =
  DATE.getFullYear() +
  "-" +
  ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
  "-" +
  ("00" + Number(DATE.getDate())).slice(-2);
const Users = [];

export default {
  name: "AnytimeScheduleDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],
  components: {
    //"vue-timepicker": VueTimepicker
    //VueGoodTable
  },
  computed: {
    Title() {
      return "随時パッケージ選択";
    },
    SummaryHeader() {
      return null;
    },
    Year() {
      return !this.date ? undefined : parseInt(this.date.split("-")[0]);
    },
    Month() {
      return !this.date ? undefined : parseInt(this.date.split("-")[1]);
    },
    Day() {
      if (!this.date) return undefined;
      const split = this.date.split("-");

      return split.length < 2 ? undefined : parseInt(split[2]);
    }
  },
  data() {
    return {
      date:
        DATE.getFullYear() +
        "-" +
        ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
        "-" +
        ("00" + Number(DATE.getDate())).slice(-2),
      data: {},
      meisais: [],
      wktantosha: [],
      tantosha: [],
      targetYm: "",
      hospitals: [],
      addmode: true,
      schedule: [],
      affiliations: []
    };
  },
  methods: {
    /*rowStyleClass(row) {
      console.log("rowStyleClass", row);
      return row.remarks === "1" ? "yellow" : "";
    },*/
    /*async getTargetMonth() {
      const startmonth = new Date(this.Year, this.Month, 21);
      let targetdt = new Date(this.Year, this.Month, this.Day);

      const m = moment(targetdt);
      if (targetdt == startmonth) {
        // targetdt.setMonth(targetdt.getMonth() + 1);
        targetdt = m.add(1, "M").toDate();
      } else if (targetdt > startmonth && targetdt.getDate() < 21) {
        //targetdt.setMonth(targetdt.getMonth() - 1);
        targetdt = m.add(-1, "M").toDate();
      } else if (targetdt < startmonth && targetdt.getDate() < 21) {
        // targetdt.setMonth(targetdt.getMonth() - 1);
        targetdt = m.add(-1, "M").toDate();
      }

      let targetMonth =
        targetdt.getFullYear() +
        "-" +
        ("00" + Number(targetdt.getMonth())).slice(-2);

      if (Number(targetdt.getMonth()) == 0) {
        const m2 = moment(targetdt);
        targetdt = m2.add(-1, "M").toDate();
        targetMonth =
          targetdt.getFullYear() +
          "-" +
          ("00" + Number(targetdt.getMonth() + 1)).slice(-2);
      }

      return targetMonth;
    },
    async confirm() {
      this.isConfirm = await this.$confirm(
        "勤務表を提出します。\nよろしいですか？"
      );
    },*/
    close(result) {
      this.resolve(result);
      this.$close();
    },
    /*async anytimeclick(pathparam) {
      console.log("click", pathparam);
      console.log("date", this.date);
      const date = new Date(this.date);
      const result = true;

      if (result) {
        this.$loading();
        try {
          this.schedule = await this.getschedule(
            date.getFullYear(),
            date.getMonth() + 1,
            pathparam
          );
          console.log("schedule", this.schedule);
          this.tableTitle = this.format;
        } catch (e) {
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }
    },*/
    /*async getschedule(year, month, pathparam) {
      console.log("getShiftMonth", year, month, pathparam);
      // 選択した所属を取得
      console.log("selected", this.selecthospital);
      this.affiliations = [];
      //this.packages.packagemeisais = [];
      this.packages = [];
      this.schedule = [];
      const param = {
        affiliations: this.selecthospital,
        targetmonth: year + "-" + ("00" + month).slice(-2),
        scheduleflag: "1",
        unfinished: "0",
        select: "1"
      };
      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);
      console.log("this.packages", this.packages);
      // API送信
      const result = await this.$get(
        this.Paths.schedule,
        pathparam + "=" + encode
      );

      console.log("api_result", result);
      if (result.length == 0) {
        await this.$warning("該当するデータがありません。", "予定データ取得");
      } else {
        return await this.setschedule(result, year, month);
      }
    },*/
    /*async setschedule(apidata, year, month) {
      console.log("setschedule", apidata);

      //データヘッダを作成
      const shedder = apidata.schedulehedder;
      console.log("schedulehedder", shedder);

      const schedules = [];
      for (let i = 0; i < shedder.length; i++) {
        schedules.push({
          index: Number(shedder[i].code),
          name: shedder[i].name
        });
      }

      //
      const hedder = apidata.hedder;

      for (let i = 0; i < hedder.length; i++) {
        const affiliation = hedder[i];

        const packages = [];
        console.log("affiliations", this.affiliations);
        const rowscount = [0];
        const rowindex = [0];

        for (let a = 0; a < affiliation.details.length; a++) {
          const b = affiliation.details[a];
          rowscount[0] = rowscount[0] + b.packagemeisai.length;
        }

        for (let j = 0; j < affiliation.details.length; j++) {
          const c = affiliation.details[j];

          const bgcolor = [""];
          //行の色設定
          //作業日が入っていない場合は、色を付ける
          if (c.scheduledate == null || c.scheduledate == "") {
            bgcolor[0] = "";
          }

          const packageList = [];
          const meisairowspan = c.packagemeisai.length;

          const tanto = [""];
          const tantonm = [""];
          const wtanto = [""];
          const wtantonm = [""];

          //担当者のセット
          for (let d = 0; d < c.tanto.length; d++) {
            if (d > 0) {
              tanto[0] = tanto[0] + ",";
              tantonm[0] = tantonm[0] + ",\r\n";
            }
            tanto[0] = tanto[0] + c.tanto[d].userid;
            tantonm[0] = tantonm[0] + c.tanto[d].username;
          }

          //作業担当者のセット
          for (let e = 0; e < c.worktanto.length; e++) {
            if (e > 0) {
              wtanto[0] = wtanto[0] + ",";
              wtantonm[0] = wtantonm[0] + ",\r\n";
            }
            wtanto[0] = wtanto[0] + c.worktanto[e].userid;
            wtantonm[0] = wtantonm[0] + c.worktanto[e].username;
          }

          for (let k = 0; k < c.packagemeisai.length; k++) {
            const s = c.packagemeisai[k];
            const packagemeisaiList = [];
            const stime = c.startTime.split(":");
            const etime = c.endTime.split(":");

            const prows = [0];

            if (k == 0) {
              prows[0] = meisairowspan;
            }

            //物件名～フロアまでは、固定
            //物件名
            if (j > 0 || k > 0) {
              packagemeisaiList.push({
                rowspan: 0,
                status: year + "-" + ("00" + month).slice(-2),
                code: "",
                sepalate: "",
                name: affiliation.hospitalName,
                width: "seven",
                color: bgcolor[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: rowscount[0],
                status: year + "-" + ("00" + month).slice(-2),
                code: "",
                sepalate: "",
                name: affiliation.hospitalName,
                width: "seven",
                color: bgcolor[0]
              });
            }
            //作業年月
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: "",
              width: "five",
              color: bgcolor[0]
            });
            //委託先
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: c.company,
              width: "five",
              color: bgcolor[0]
            });
            //作業内容
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.workpattern,
              width: "seven",
              color: bgcolor[0]
            });
            //作業日時
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: c.scheduledate,
              width: "seven",
              color: bgcolor[0]
            });
            //開始時刻
            if (stime.length > 1) {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: stime[0] + ":" + stime[1],
                width: "five",
                color: bgcolor[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: "",
                width: "five",
                color: bgcolor[0]
              });
            }
            //終了時刻
            if (etime.length > 1) {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: etime[0] + ":" + etime[1],
                width: "five",
                color: bgcolor[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: "",
                width: "five",
                color: bgcolor[0]
              });
            }
            //作業担当者
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: wtanto[0],
              sepalate: "",
              name: wtantonm[0],
              width: "six",
              color: bgcolor[0]
            });
            //担当者
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: tanto[0],
              sepalate: "",
              name: tantonm[0],
              width: "six",
              color: bgcolor[0]
            });
            //フロア
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.floor,
              width: "seven",
              color: bgcolor[0]
            });
            //エリア
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.area,
              width: "seven",
              color: bgcolor[0]
            });
            const warea = [""];
            const wareasuu = [""];
            const st = [""];
            const add = [""];
            const sep = [""];
            const key = [""];
            const ref = [""];
            const other = [""];
            const nextc = [""];
            const allncflag = [false];
            for (let l = 0; l < s.workarea.length; l++) {
              if (l > 0) {
                warea[0] = warea[0] + ",";
                wareasuu[0] = wareasuu[0] + ",";
                st[0] = st[0] + ",";
                add[0] = add[0] + ",";
                sep[0] = sep[0] + ",";
                key[0] = key[0] + ",";
                ref[0] = ref[0] + ",";
                other[0] = other[0] + ",";
                nextc[0] = nextc[0] + ",";
              }
              warea[0] = warea[0] + s.workarea[l].workarea;
              st[0] = st[0] + s.workarea[l].displayflag;
              wareasuu[0] = wareasuu[0] + s.workarea[l].workareaSuu;
              add[0] = add[0] + s.workarea[l].addflag;
              sep[0] = sep[0] + s.workarea[l].sepalationflag;
              key[0] = key[0] + s.workarea[l].keyid;
              ref[0] = ref[0] + s.workarea[l].referenceworkareaid;
              other[0] = other[0] + s.workarea[l].processingscheduleother;
              if (
                s.workarea[l].processingschedulenextcycle != null &&
                s.workarea[l].processingschedulenextcycle != undefined
              ) {
                nextc[0] = nextc[0] + s.workarea[l].processingschedulenextcycle;

                if (
                  s.workarea[l].processingschedulenextcycle == "0" &&
                  allncflag[0] == true
                ) {
                  allncflag[0] = false;
                }
                //次回サイクルが設定されている場合は、色付けを中止
                /*if (s.workarea[l].processingschedulenextcycle == "1" &&  bgcolor[0] == "yellow") {
                   bgcolor[0] = "";
                }
              } else {
                nextc[0] = nextc[0] + "0";
              }
            }
            //作業箇所
            packagemeisaiList.push({
              rowspan: 1,
              status: st[0],
              code: add[0],
              sepalate: sep[0],
              name: warea[0],
              keyid: key[0],
              referenceid: ref[0],
              width: "workarea",
              color: bgcolor[0],
              other: other[0],
              suu: wareasuu[0],
              nextcycle: nextc[0]
            });
            //備考
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: c.biko,
              width: "fifteen",
              color: bgcolor[0]
            });
            //その他引渡が必要な項目
            packagemeisaiList.push({
              rowspan: 0,
              addflag: s.addflag,
              sepalationflag: s.sepalationflag,
              referencemeisaiid: "",
              processingschedulenextmonth: "",
              processingschedulehold: "",
              processingschedulenextcycle: "",
              processingscheduleother: other[0],
              width: "",
              color: bgcolor[0],
              weekday: c.weekday
            });

            //以下の内容は、画面には表示させないがデータ処理場必須のため渡す(必須:rowspan=0)

            if (k == 0) {
              packageList.push({
                index: j,
                rowindex: rowindex[0],
                hojinCd: affiliation.hojinCd,
                hospitalCd: affiliation.hospitalCd,
                packageNo: c.packageNo,
                packageMeisaiNo: s.packageMeisaiNo,
                packagemeisais: packagemeisaiList,
                rowspan: meisairowspan,
                unfinished: false,
                keyId: c.keyid,
                yotei: "",
                select: "0",
                bikoflag: true
              });
            } else {
              packageList.push({
                index: j,
                rowindex: rowindex[0],
                hojinCd: affiliation.hojinCd,
                hospitalCd: affiliation.hospitalCd,
                packageNo: c.packageNo,
                packageMeisaiNo: s.packageMeisaiNo,
                packagemeisais: packagemeisaiList,
                rowspan: 0,
                unfinished: false,
                keyId: c.keyid,
                yotei: "",
                select: "0",
                bikoflag: true
              });
            }
            rowindex[0] = rowindex[0] + 1;
          }

          packages.push(packageList);
          console.log("packages", this.packages);
        }

        const scounts = packages.length;

        this.workaffiliation.push({
          hojinCd: affiliation.hojinCd,
          hospitalCd: affiliation.hospitalCd,
          hospitalName: affiliation.hospitalName,
          index: i,
          packages: packages,
          scount: scounts
        });
      }

      //this.scount = this.packages.length;
      console.log("scount", this.scount);

      const schedule = schedules;
      console.log("affiliations", this.affiliations);

      return schedule;
    },
    async getData(api) {
      let data;
      switch (this.select) {
        default: {
          if (api) {
            this.$loading();

            try {
              data = await this.getRecordByUser();
              const bodies = [];
              data.body.forEach(e => {
                if (e.recordid !== null) bodies.push(e);
              });
              data.body = bodies;
            } catch (e) {
              console.log(e);
              this.$error(e.message);
            } finally {
              this.$unloading();
            }
          } else {
            data = Dummy.getPrivateRostar();
          }
          break;
        }
      }
      await this.setAttendanceDetails(data);
    },*/
    /*async getRecordByUser() {
      const targetMonth = await this.getTargetMonth();
      const param = {
        incode: this.user.code ? this.user.code : this.user,
        targetmonth: targetMonth
      };
      console.log("getRecordByUser", param);
      const encode = encodeURI(JSON.stringify(param));
      return await this.$get(this.Paths.attendance, "query=" + encode);
    },*/
    async anytimefix(aindex, pindex, pathparam) {
      console.log("fix", aindex + "-" + pindex);
      //console.log("fix-index", item.index);
      const packs = this.affiliations[aindex].packages[pindex];
      console.log(packs);

      const props = { args: packs };
      console.log(props);
      //item.employee = this.user.code ? this.user.code : this.user;
      const result = await this.$showDialog(ScheduleFixDialog, props);

      console.log("result", result);

      if (result) {
        const date = new Date(this.date);

        this.schedule = await this.getShiftMonth(
          date.getFullYear(),
          date.getMonth() + 1,
          pathparam
        );
      }
    },
    async getHospital() {
      console.log("getHospital");
      return await this.$get(this.Paths.hpFilter);
    }
  },
  async created() {
    //console.log("created", this.date);

    this.$loading();

    try {
      const args = this.args;
      console.log("args", this.args);
      this.schedule = args[0].schedule;
      this.affiliations = args[0].affiliations;
      console.log("schedule", this.schedule);
      console.log("affiliations", this.affiliations);
      //this.date = args[0].date;
      //await this.anytimeclick('query');
    } finally {
      this.$unloading();
    }
  },
  mounted() {
    console.log("mounted");
  }
};
</script>

<style lang="scss" scoped>
.office {
  table {
    width: 99%;
    border-collapse: collapse;
    display: block;
  }
}
.five {
  width: 5%;
}
.six {
  width: 6%;
}
.seven {
  width: 7%;
}
.fifteen {
  width: 11%;
}
.workarea {
  width: 20%;
}
.bold-text {
  font-weight: bold;
}
.pulldown-z-index {
  z-index: 3;
}
.fixed {
  position: sticky;
  left: 0;
  color: #333;
  background-color: white;
  z-index: 2;
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
  }
}
.private {
  table {
    width: 100%;
  }
}
.person {
  width: 50px;
}
.person::after {
  content: "ms";
}
.shift-event {
  font-size: 12px;
}

.warning-col {
  background-color: #ffcc66;
}

.worktime-span {
  float: right;
  margin-right: 15px;
}
</style>

export default class ScheduleQuery {
  affiliations: string;
  targetmonth: string;
  scheduleflag: string;
  unfinished: string;
  keyid: string;
  select: string;
  constructor(
    affiliations = "",
    targetmonth = "",
    scheduleflag = "",
    unfinished = "",
    keyid = "",
    select = ""
  ) {
    this.affiliations = affiliations;
    this.targetmonth = targetmonth;
    this.scheduleflag = scheduleflag;
    this.unfinished = unfinished;
    this.keyid = keyid;
    this.select = select;
  }
}
